import React from "react";
import { useState } from "react";
import "./Truther.css";
import { useEffect } from "react";
import AdSenseAd from "./AdSenseAd";

const Truther = () => {
    const tarotArr = [
        "The Fool", "The Magician", "The High Priestess", "The Empress", "The Emperor",
         "The Hierophant", "The Lovers", "The Chariot", "Strength", "The Hermit",
          "Wheel of Fortune", "Justice", "The Hanged Man", "Death", "Temperance",
           "The Devil", "The Tower", "The Star", "The Moon", "The Sun",
            "Judgment", "The World", "Ace of Wands", "Two of Wands", "Three of Wands",
             "Four of Wands", "Five of Wands", "Six of Wands", "Seven of Wands", "Eight of Wands",
              "Nine of Wands", "Ten of Wands", "Page of Wands", "Knight of Wands", "Queen of Wands",
               "King of Wands", "Ace of Pentacles", "Two of Pentacles", "Three of Pentacles", "Four of Pentacles",
                "Five of Pentacles", "Six of Pentacles", "Seven of Pentacles", "Eight of Pentacles", "Nine of Pentacles",
                 "Ten of Pentacles", "Page of Pentacles", "Knight of Pentacles", "Queen of Pentacles", "King of Pentacles",
                  "Ace of Swords", "Two of Swords", "Three of Swords", "Four of Swords", "Five of Swords",
                   "Six of Swords", "Seven of Swords", "Eight of Swords", "Nine of Swords", "Ten of Swords",
                    "Page of Swords", "Knight of Swords", "Queen of Swords", "King of Swords", "Ace of Cups",
                     "Two of Cups", "Three of Cups", "Four of Cups", "Five of Cups", "Six of Cups",
                      "Seven of Cups", "Eight of Cups", "Nine of Cups", "Ten of Cups", "Page of Cups",
                       "Knight of Cups", "Queen of Cups", "King of Cups"
    ]

    const tarotRev = [
        "", "Reversed"
    ]

    const [tarotReading, setTarotReading] = useState("Pending");
    const [reverseCard, setReverseCard] = useState("");
    const [tarotCard, setTarotCard] = useState(<img src = "/image/Jester.jpg"/>);

    const buttonClick = () => {
        const tarotRNG = tarotArr[Math.floor(tarotArr.length * Math.random())];
        const revRNG = tarotRev[Math.floor(tarotRev.length * Math.random())];
        setTarotReading(tarotRNG);
        setReverseCard(revRNG);
    };

    useEffect(() => {
        if (tarotReading === "The Fool") {
            setTarotCard(<img src = "/image/The Fool.jpg"/>);
        } else if (tarotReading === "The Magician") {
            setTarotCard(<img src = "/image/The Magician.jpg"/>);
        } else if (tarotReading === "The High Priestess") {
            setTarotCard(<img src = "/image/The High Priestess.jpg"/>);
        } else if (tarotReading === "The Empress") {
            setTarotCard(<img src = "/image/The Empress.jpg"/>);
        } else if (tarotReading === "The Emperor") {
            setTarotCard(<img src = "/image/The Emperor.jpg"/>);
        } else if (tarotReading === "The Hierophant") {
            setTarotCard(<img src = "/image/The Hierophant.jpg"/>);
        } else if (tarotReading === "The Lovers") {
            setTarotCard(<img src = "/image/The Lovers.jpg"/>);
        } else if (tarotReading === "The Chariot") {
            setTarotCard(<img src = "/image/The Chariot.jpg"/>);
        } else if (tarotReading === "Strength") {
            setTarotCard(<img src = "/image/Strength.jpg"/>);
        } else if (tarotReading === "The Hermit") {
            setTarotCard(<img src = "/image/The Hermit.jpg"/>);
        } else if (tarotReading === "Wheel of Fortune") {
            setTarotCard(<img src = "/image/Wheel of Fortune.jpg"/>);
        } else if (tarotReading === "Justice") {
            setTarotCard(<img src = "/image/Justice.jpg"/>);
        } else if (tarotReading === "The Hanged Man") {
            setTarotCard(<img src = "/image/The Hanged Man.jpg"/>);
        } else if (tarotReading === "Death") {
            setTarotCard(<img src = "/image/Death.jpg"/>);
        } else if (tarotReading === "Temperance") {
            setTarotCard(<img src = "/image/Temperance.jpg"/>);
        } else if (tarotReading === "The Devil") {
            setTarotCard(<img src = "/image/The Devil.jpg"/>);
        } else if (tarotReading === "The Tower") {
            setTarotCard(<img src = "/image/The Tower.jpg"/>);
        } else if (tarotReading === "The Star") {
            setTarotCard(<img src = "/image/The Star.jpg"/>);
        } else if (tarotReading === "The Moon") {
            setTarotCard(<img src = "/image/The Moon.jpg"/>);
        } else if (tarotReading === "The Sun") {
            setTarotCard(<img src = "/image/The Sun.jpg"/>);
        } else if (tarotReading === "Judgment") {
            setTarotCard(<img src = "/image/Judgment.jpg"/>);
        } else if (tarotReading === "The World") {
            setTarotCard(<img src = "/image/The World.jpg"/>);
        } else if (tarotReading === "Ace of Wands") {
            setTarotCard(<img src = "/image/Ace of Wands.jpg"/>);
        } else if (tarotReading === "Two of Wands") {
            setTarotCard(<img src = "/image/Two of Wands.jpg"/>);
        } else if (tarotReading === "Three of Wands") {
            setTarotCard(<img src = "/image/Three of Wands.jpg"/>);
        } else if (tarotReading === "Four of Wands") {
            setTarotCard(<img src = "/image/Four of Wands.jpg"/>);
        } else if (tarotReading === "Five of Wands") {
            setTarotCard(<img src = "/image/Five of Wands.jpg"/>);
        } else if (tarotReading === "Six of Wands") {
            setTarotCard(<img src = "/image/Six of Wands.jpg"/>);
        } else if (tarotReading === "Seven of Wands") {
            setTarotCard(<img src = "/image/Seven of Wands.jpg"/>);
        } else if (tarotReading === "Eight of Wands") {
            setTarotCard(<img src = "/image/Eight of Wands.jpg"/>);
        } else if (tarotReading === "Nine of Wands") {
            setTarotCard(<img src = "/image/Nine of Wands.jpg"/>);
        } else if (tarotReading === "Ten of Wands") {
            setTarotCard(<img src = "/image/Ten of Wands.jpg"/>);
        } else if (tarotReading === "Page of Wands") {
            setTarotCard(<img src = "/image/Page of Wands.jpg"/>);
        } else if (tarotReading === "Knight of Wands") {
            setTarotCard(<img src = "/image/Knight of Wands.jpg"/>);
        } else if (tarotReading === "Queen of Wands") {
            setTarotCard(<img src = "/image/Queen of Wands.jpg"/>);
        } else if (tarotReading === "King of Wands") {
            setTarotCard(<img src = "/image/King of Wands.jpg"/>);
        } else if (tarotReading === "Ace of Pentacles") {
            setTarotCard(<img src = "/image/Ace of Pentacles.jpg"/>);
        } else if (tarotReading === "Two of Pentacles") {
            setTarotCard(<img src = "/image/Two of Pentacles.jpg"/>);
        } else if (tarotReading === "Three of Pentacles") {
            setTarotCard(<img src = "/image/Three of Pentacles.jpg"/>);
        } else if (tarotReading === "Four of Pentacles") {
            setTarotCard(<img src = "/image/Four of Pentacles.jpg"/>);
        } else if (tarotReading === "Five of Pentacles") {
            setTarotCard(<img src = "/image/Five of Pentacles.jpg"/>);
        } else if (tarotReading === "Six of Pentacles") {
            setTarotCard(<img src = "/image/Six of Pentacles.jpg"/>);
        } else if (tarotReading === "Seven of Pentacles") {
            setTarotCard(<img src = "/image/Seven of Pentacles.jpg"/>);
        } else if (tarotReading === "Eight of Pentacles") {
            setTarotCard(<img src = "/image/Eight of Pentacles.jpg"/>);
        } else if (tarotReading === "Nine of Pentacles") {
            setTarotCard(<img src = "/image/Nine of Pentacles.jpg"/>);
        } else if (tarotReading === "Ten of Pentacles") {
            setTarotCard(<img src = "/image/Ten of Pentacles.jpg"/>);
        } else if (tarotReading === "Page of Pentacles") {
            setTarotCard(<img src = "/image/Page of Pentacles.jpg"/>);
        } else if (tarotReading === "Knight of Pentacles") {
            setTarotCard(<img src = "/image/Knight of Pentacles.jpg"/>);
        } else if (tarotReading === "Queen of Pentacles") {
            setTarotCard(<img src = "/image/Queen of Pentacles.jpg"/>);
        } else if (tarotReading === "King of Pentacles") {
            setTarotCard(<img src = "/image/King of Pentacles.jpg"/>);
        } else if (tarotReading === "Ace of Swords") {
            setTarotCard(<img src = "/image/Ace of Swords.jpg"/>);
        } else if (tarotReading === "Two of Swords") {
            setTarotCard(<img src = "/image/Two of Swords.jpg"/>);
        } else if (tarotReading === "Three of Swords") {
            setTarotCard(<img src = "/image/Three of Swords.jpg"/>);
        } else if (tarotReading === "Four of Swords") {
            setTarotCard(<img src = "/image/Four of Swords.jpg"/>);
        } else if (tarotReading === "Five of Swords") {
            setTarotCard(<img src = "/image/Five of Swords.jpg"/>);
        } else if (tarotReading === "Six of Swords") {
            setTarotCard(<img src = "/image/Six of Swords.jpg"/>);
        } else if (tarotReading === "Seven of Swords") {
            setTarotCard(<img src = "/image/Seven of Swords.jpg"/>);
        } else if (tarotReading === "Eight of Swords") {
            setTarotCard(<img src = "/image/Eight of Swords.jpg"/>);
        } else if (tarotReading === "Nine of Swords") {
            setTarotCard(<img src = "/image/Nine of Swords.jpg"/>);
        } else if (tarotReading === "Ten of Swords") {
            setTarotCard(<img src = "/image/Ten of Swords.jpg"/>);
        } else if (tarotReading === "Page of Swords") {
            setTarotCard(<img src = "/image/Page of Swords.jpg"/>);
        } else if (tarotReading === "Knight of Swords") {
            setTarotCard(<img src = "/image/Knight of Swords.jpg"/>);
        } else if (tarotReading === "Queen of Swords") {
            setTarotCard(<img src = "/image/Queen of Swords.jpg"/>);
        } else if (tarotReading === "King of Swords") {
            setTarotCard(<img src = "/image/King of Swords.jpg"/>);
        } else if (tarotReading === "Ace of Cups") {
            setTarotCard(<img src = "/image/Ace of Cups.jpg"/>);
        } else if (tarotReading === "Two of Cups") {
            setTarotCard(<img src = "/image/Two of Cups.jpg"/>);
        } else if (tarotReading === "Three of Cups") {
            setTarotCard(<img src = "/image/Three of Cups.jpg"/>);
        } else if (tarotReading === "Four of Cups") {
            setTarotCard(<img src = "/image/Four of Cups.jpg"/>);
        } else if (tarotReading === "Five of Cups") {
            setTarotCard(<img src = "/image/Five of Cups.jpg"/>);
        } else if (tarotReading === "Six of Cups") {
            setTarotCard(<img src = "/image/Six of Cups.jpg"/>);
        } else if (tarotReading === "Seven of Cups") {
            setTarotCard(<img src = "/image/Seven of Cups.jpg"/>);
        } else if (tarotReading === "Eight of Cups") {
            setTarotCard(<img src = "/image/Eight of Cups.jpg"/>);
        } else if (tarotReading === "Nine of Cups") {
            setTarotCard(<img src = "/image/Nine of Cups.jpg"/>);
        } else if (tarotReading === "Ten of Cups") {
            setTarotCard(<img src = "/image/Ten of Cups.jpg"/>);
        } else if (tarotReading === "Page of Cups") {
            setTarotCard(<img src = "/image/Page of Cups.jpg"/>);
        } else if (tarotReading === "Knight of Cups") {
            setTarotCard(<img src = "/image/Knight of Cups.jpg"/>);
        } else if (tarotReading === "Queen of Cups") {
            setTarotCard(<img src = "/image/Queen of Cups.jpg"/>);
        } else if (tarotReading === "King of Cups") {
            setTarotCard(<img src = "/image/King of Cups.jpg"/>);
        } if (reverseCard == "Reversed") {
            document.getElementById("tarotCard").style.transform = "rotate(180deg)";
        } else if (reverseCard == "") {
            document.getElementById("tarotCard").style.transform = "rotate(0deg)";
        };
    });
    
    return (
        <div>
            
            <div>
                <div id= "tarotCard">{tarotCard}</div>
            </div>
            <div className="infoBox">
                Your Card Is {reverseCard} {tarotReading}
            </div>
            <button className="clicka" onClick={buttonClick}>
                Generate
            </button>
            <AdSenseAd />
        </div>
    )
};

export default Truther;