import React from 'react';
import { Helmet } from 'react-helmet';

const AdSenseAd = () => {
  return (
    <>
      <Helmet>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6569529949540331" crossOrigin="anonymous"></script>
      </Helmet>
      
      <ins className="adsbygoogle"
           style={{ display: 'block' }}
           data-ad-client="ca-pub-6569529949540331"
           data-ad-slot="6294260581"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
      <script>{`
        (adsbygoogle = window.adsbygoogle || []).push({});
      `}</script>
    </>
  );
}

export default AdSenseAd;